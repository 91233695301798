import type { Category } from '@scayle/storefront-api'

export const useFimCategories = (
  categories: Category[] | Category | undefined,
  selectedCategory: MaybeRefOrGetter<Category | undefined>,
) => {
  const route = useRoute()
  const key = `categories.value-${route.path}')`
  const flattenedCategories = useState(key, () =>
    Array.isArray(categories)
      ? flattenCategories(categories)
      : categories
        ? [categories]
        : [],
  )

  // const _selectedCategory = toValue(selectedCategory)

  const parentIds = computed(
    () =>
      toValue(selectedCategory)?.rootlineIds?.filter(
        (id) => id !== toValue(selectedCategory)?.id,
      ) ?? [],
  )

  const categoryHeadline = computed(() => {
    const currentCategory = toValue(selectedCategory)

    // Use headline override if set https://aboutyou.atlassian.net/browse/SCFIM-2105
    const headlineOverride = currentCategory?.shopLevelCustomData
      ?.Headline_Override as string
    if (headlineOverride) {
      return headlineOverride
    }

    const [addons]: string[][] =
      (
        [
          currentCategory,
          [...parentIds.value].reverse().map((id) => {
            return flattenedCategories.value.find((cat) => cat?.id === id)
          }),
        ] as Category[]
      )
        .map((category) => {
          return [
            category?.shopLevelCustomData?.Headline_Prefix as string,
            category?.shopLevelCustomData?.Headline_Suffix as string,
          ]
        })
        .filter(([prefix, suffix]) => Boolean(prefix) || Boolean(suffix)) || []

    const [prefix, suffix] = addons || []

    return [prefix, currentCategory?.name, suffix].filter(Boolean).join(' ')
  })

  const getCategoryBySlug = (slug?: string) => {
    if (!slug) {
      return undefined
    }

    return flattenedCategories.value.find(
      (category) => category.slug.toLowerCase() === slug.toLowerCase(),
    )
  }

  return { flattenedCategories, categoryHeadline, getCategoryBySlug }
}
